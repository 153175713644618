import { useMotionValueValue } from '@graphcommerce/framer-utils'
import { ElementNode } from '@graphcommerce/graphcms-ui/components/RichText/types'
import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  DesktopNavBar,
  iconCustomerService,
  iconHeart,
  NavigationFab,
  MenuFabSecondaryItem,
  IconSvg,
  DesktopNavItem,
  iconChevronDown,
  NavigationProvider,
  NavigationOverlay,
  useNavigationSelection,
  useMemoDeep,
  iconSearch,
  iconNewspaper,
  useFabSize,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Divider, Fab, Link, Typography, useTheme } from '@mui/material'
import { motionValue } from 'framer-motion'
import { useRouter } from 'next/router'
import { ChangeEvent, useCallback, useRef } from 'react'
import { CustomCartIcon } from '../Icons/CustomCartIcon'
import DaFolderIcon from '../Icons/FolderIcon.svg'
import { OrganizationSchema } from '../SchemaScripts/OrganizationSchema'
import { SooqrScript } from '../Sooqr/SooqrScript'
import { StoreNotice } from '../StoreNotice/StoreNotice'
import { normalizeHref } from '../normalizeHref'
import { DesktopTopHeader } from './DesktopTopHeader'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { LayoutDefault, LayoutDefaultProps } from './LayoutDefault'
import { Logo } from './Logo'
import { MobileStickyBar } from './MobileStickyBar'
import { SearchBar } from './components/SearchBar'
import { SearchTextField } from './components/SearchTextField'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'> & {
    infoForm?: {
      scriptId: string
    } | null
    callADruggist?: {
      content: {
        raw: ElementNode
      }
    } | null
  }

const handleSearchChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const el = globalThis.$jQ(e.target)
  const instance = Object.values(
    (globalThis.websight?.sooqr?.instances as unknown as object) ?? {},
  )[0]
  instance.queryField = el
  instance.searchCallback.delayedCallback({ filterInitiated: false }, el)
}

const showSearch = motionValue(false)

function SearchBarWrapper(props: { searchInputRef: React.RefObject<HTMLInputElement> }) {
  const { searchInputRef } = props
  const show = useMotionValueValue(showSearch, (v) => v)

  return (
    <SearchBar
      show={show}
      onChange={handleSearchChange}
      onHide={() => showSearch.set(false)}
      searchInputRef={searchInputRef}
    />
  )
}

function SearchBarButton(props: { searchInputRef: React.RefObject<HTMLInputElement> }) {
  const { searchInputRef } = props
  const size = useFabSize('responsive')

  return (
    <Box
      onClick={() => {
        showSearch.set(true)
        // Fix to focus the <SearchTextField /> input in SearchBar.tsx on iOS devices.
        if (searchInputRef.current) {
          searchInputRef.current.focus()
        }
      }}
      sx={(theme) => ({
        display: 'flex',
        bgcolor: 'background.default',
        height: size,
        borderRadius: `calc(${size} / 2)`,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.shadows[4],
        typography: 'body1',
        color: 'text.secondary',
      })}
    >
      <Trans id='Search...' />
      <IconSvg src={iconSearch} sx={{ color: 'text.secondary', ml: 1 }} />
    </Box>
  )
}

export function LayoutNavigation(props: LayoutNavigationProps) {
  const {
    footer,
    menu,
    children,
    usps,
    keurmerkUsps,
    salesCategoryMenu,
    storeNotice,
    mainMenu,
    callADruggist,
    infoForm,
    ...uiProps
  } = props

  const selection = useNavigationSelection()
  const router = useRouter()
  const theme = useTheme()
  const size = useFabSize('responsive')
  const searchInputRef = useRef<HTMLInputElement>(null)

  const Search = useCallback(
    () => (
      <>
        <SooqrScript callADruggist={callADruggist} infoForm={infoForm} />
        <SearchTextField
          id='search'
          variant='outlined'
          placeholder={i18n._(/* i18n */ 'Search for...')}
          size='small'
          hiddenLabel
          onChange={handleSearchChange}
        />
      </>
    ),
    [callADruggist, infoForm],
  )

  return (
    <>
      <OrganizationSchema />
      <NavigationProvider
        selection={selection}
        items={useMemoDeep(
          () => [
            {
              id: 'home',
              name: i18n._(/* i18n */ 'Home'),
              href: '/',
            },
            {
              id: menu?.items?.[0]?.uid ?? 'products',
              name: 'Producten',
              type: 1,
              childItems: [...magentoMenuToNavigation(menu, false)],
            },
            ...(mainMenu?.pageLinks?.map((pageLinks) => ({
              ...{
                id: pageLinks.id,
                name: pageLinks.title,
                href: pageLinks.url,
              },
            })) || []),
            <Divider sx={{ my: theme.spacings.xs }} />,
            <CustomerMenuFabItem
              onClick={() => selection.set(false)}
              key='account'
              guestHref='/account/signin'
              authHref='/account'
            >
              <Trans id='Account' />
            </CustomerMenuFabItem>,
            <MenuFabSecondaryItem
              key='service'
              icon={<IconSvg src={iconCustomerService} size='medium' />}
              href='/klantenservice'
            >
              <Trans id='Customer Service' />
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='magazine'
              icon={<IconSvg src={iconNewspaper} size='medium' />}
              href='/magazine'
            >
              <Trans id='Magazine' />
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='folder'
              icon={<IconSvg src={DaFolderIcon} size='medium' />}
              href='/over-da/folder'
            >
              <Trans id='Folder' />
            </MenuFabSecondaryItem>,

            <WishlistMenuFabItem key='wishlist' icon={<IconSvg src={iconHeart} size='medium' />}>
              <Trans id='Wishlist' />
            </WishlistMenuFabItem>,
          ],
          [mainMenu?.pageLinks, menu, selection, theme.spacings.xs],
        )}
      >
        <NavigationOverlay
          stretchColumns={false}
          variantSm='left'
          sizeSm='full'
          justifySm='start'
          itemWidthSm='70vw'
          variantMd='left'
          sizeMd='full'
          justifyMd='start'
          itemWidthMd='230px'
          mouseEvent='hover'
          itemPadding='md'
          sx={{
            '& > ul > li > a, & > ul > li > [role=button]': {
              '& span': {
                typography: 'h5',
              },
            },
          }}
        />
      </NavigationProvider>

      <LayoutDefault
        {...uiProps}
        beforeHeader={<DesktopTopHeader usps={usps} />}
        searchBar={<SearchBarWrapper searchInputRef={searchInputRef} />}
        noSticky={router.asPath.split('?')[0] === '/'}
        sx={{
          '& .LayoutDefault-header': {
            background: theme.palette.background.paper,
            mb: 0,
            [theme.breakpoints.up('md')]: {
              position: 'sticky',
              top: '34px',
            },
          },
        }}
        header={
          <>
            <Logo />
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flex: '1 0 auto',
                justifyContent: 'space-between',
                p: `0 ${theme.page.horizontal}`,
              }}
            >
              <Link href='/'>
                <Typography fontWeight='bold' variant='h5'>
                  de vriendelijke specialist
                </Typography>
              </Link>
              <Link href='/winkelzoeker' color='textPrimary' underline='always'>
                Winkels
              </Link>
            </Box>
            <DesktopNavBar
              sx={{
                '& .MuiLink-root': {
                  position: 'relative',
                },
                '& .IconSvg': {
                  top: '15px',
                  left: '100%',
                  fontSize: '21px !important',
                  position: 'absolute',
                },
              }}
            >
              {menu?.items?.map((item) => (
                <DesktopNavItem
                  key={item?.uid}
                  onClick={() => selection.set([menu?.items?.[0]?.uid || ''])}
                >
                  <Trans id='Products' />
                  <IconSvg src={iconChevronDown} size='large' />
                </DesktopNavItem>
              ))}
              {mainMenu?.pageLinks?.map((pageLinks) => (
                <DesktopNavItem key={pageLinks.id} href={normalizeHref(pageLinks.url)}>
                  <Trans id={pageLinks.title} />
                </DesktopNavItem>
              ))}
            </DesktopNavBar>

            <DesktopNavActions>
              <Box
                sx={{
                  '& .MuiFormControl-root': {
                    minWidth: '282px',
                    mr: theme.spacings.xs,
                    ml: theme.spacings.md,
                  },
                }}
              >
                {!router.pathname.startsWith('/search') && <Search />}
              </Box>
              <Fab
                href='/klantenservice'
                aria-label={i18n._(/* i18n */ 'Account')}
                size='large'
                color='inherit'
              >
                <IconSvg src={iconCustomerService} size='large' />
              </Fab>
              <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
              <CustomerFab guestHref='/account/signin' authHref='/account' />
            </DesktopNavActions>
          </>
        }
        footer={<Footer footer={footer} keurmerkUsps={keurmerkUsps} usps={usps} />}
        cartFab={
          <CartFab
            sx={{
              '& .CartFab-shadow': {
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
              },
            }}
            icon={<CustomCartIcon />}
          />
        }
        stickyBar={
          <MobileStickyBar
            cartFab={
              <CartFab
                sx={{
                  width: size,
                  height: size,
                  '& .MuiButtonBase-root': {
                    background: `${theme.palette.background.default} !important`,
                    width: size,
                    height: size,
                  },
                }}
                icon={<CustomCartIcon />}
                size='medium'
              />
            }
            menuFab={
              <NavigationFab
                onClick={() => selection.set([])}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    visibility: 'hidden',
                  },
                  height: size,
                  width: size,
                  '& button': {
                    height: size,
                    width: size,
                    backgroundColor: theme.palette.background.default,
                    '&:focus,&:hover': {
                      backgroundColor: theme.palette.background.default,
                    },
                    '& svg': {
                      color: theme.palette.common.black,
                    },
                  },
                }}
              />
            }
            searchBar={
              !router.pathname.startsWith('/search') && (
                <SearchBarButton searchInputRef={searchInputRef} />
              )
            }
          />
        }
      >
        <StoreNotice storeNotice={storeNotice} />
        {children}
      </LayoutDefault>
    </>
  )
}
